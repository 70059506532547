<template>
  <PageTemplate>
		<Grid>
			<GridContainer size="12">
				<Heading size="3">Themes</Heading>
			</GridContainer>
			<GridContainer size="12 9@md">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">How Themes Work</Heading>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack direction="column" align="left" space="2">
							<Paragraph>Themes are a combination of predefined CSS and SCSS variables that are used to customize the Vue.js components. They are used to customize component styles to fit the specific aesthetic of a brand or product.</Paragraph>
							<Paragraph>The predefined variables are referred to as design tokens. Tokens are used instead of hard coded values to ensure a better UI consistency across different platforms.</Paragraph>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="3">
			</GridContainer>
			<GridContainer size="12 3@md">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Available Themes</Heading>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack direction="column" align="left" space="1">
							<Paragraph>Anna Robbins</Paragraph>
							<Paragraph>Dallas</Paragraph>
							<Paragraph>Edison</Paragraph>
							<Paragraph>Havelock</Paragraph>
							<Paragraph>Sedona</Paragraph>
							<Paragraph>Windermere</Paragraph>
							<Paragraph>Design System</Paragraph>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12 9@md">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Theme Switching</Heading>
							<Paragraph>Select a theme in the drop down at the top right of the page. This will change the components below to the unique theme styles.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack direction="column" align="left" space="1">
							<Stage>
								<Theme ar>
									<Paragraph>Anna Robbins Theme</Paragraph>
								</Theme>
								<Theme dl>
									<Paragraph>Dallas Theme</Paragraph>
								</Theme>
								<Theme ed>
									<Paragraph>Edison Theme</Paragraph>
								</Theme>
								<Theme hv>
									<Paragraph>Havelock Theme</Paragraph>
								</Theme>
								<Theme sd>
									<Paragraph>Sedona Theme</Paragraph>
								</Theme>
								<Theme wm>
									<Paragraph>Windermere Theme</Paragraph>
								</Theme>
								<Theme ds>
									<Paragraph>Design System Theme</Paragraph>
								</Theme>
								<Stack space="2" wrap>
									<Button label="Button" color="secondary" size="small" />
									<Heading size="4">Heading</Heading>
									<Icon icon="favorite" size="medium" background="primary" color="light" />
									<Link text="Link" url="" display="inline" />
								</Stack>
							</Stage>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
		</Grid>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/designsystem/template/Page'; 
export default {
  components: {
		PageTemplate
  },
  data() {
    return {
      theme: ''
		}
  },
  mounted() {
		setTimeout(() =>{ 
			this.stageTheme(this.theme);
		}, 30);
  },
}
</script>

<style lang="scss" scoped>
</style>
